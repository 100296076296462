<template>
  <b-tabs
    pills
  >
    <b-tab
      active
      lazy
    >
      <template #title>
        <feather-icon icon="CalendarIcon" />
        <span>Zamanlanmış Görevler</span>
      </template>
      <cronjob-log-card />
    </b-tab>
  </b-tabs>
</template>
<script>
import {
  BTab, BTabs,
} from 'bootstrap-vue'
import CronjobLogCard from '@/views/ManagerTools/Components/ProcessMonitor/CronjobLogCard.vue'

export default {
  name: 'ProcessMonitor',
  components: {
    CronjobLogCard,
    BTab,
    BTabs,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  created() {
    // erişim kısıtlama işlemi
    if (this.userData.id !== '352' && this.userData.id !== '1') {
      this.$swal({
        icon: 'error',
        title: 'Uyarı',
        text: 'Bu alana giriş için yetkiniz bulunmuyor.',
        confirmButtonText: this.$store.state.app.removeResultClose,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
      this.$router.push('/')
    }
  },
}
</script>
