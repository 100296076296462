<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          Zamanlanmış Görev Durum Kayıtları
        </b-card-title>
        <div class="w-75">
          <b-row class="d-flex justify-content-center align-items-center">
            <b-col>
              <b-form-group
                label="Başlangıç Tarihi"
                label-for="sdate"
              >
                <b-input-group>
                  <b-form-datepicker
                    id="sdate"
                    v-model="filter.sdate"
                    v-bind="{labelNoDateSelected: 'Başlangıç Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                    locale="tr"
                    start-weekday="1"
                  />
                  <b-form-timepicker
                    id="stime"
                    v-model="filter.stime"
                    :locale="locale"
                    v-bind="labels[locale] || {}"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Bitiş Tarihi"
                label-for="edate"
              >
                <b-input-group>
                  <b-form-datepicker
                    id="edate"
                    v-model="filter.edate"
                    v-bind="{labelNoDateSelected: 'Bitiş Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                    locale="tr"
                    start-weekday="1"
                  />
                  <b-form-timepicker
                    id="etime"
                    v-model="filter.etime"
                    :locale="locale"
                    v-bind="labels[locale] || {}"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="auto">
              <b-button
                variant="primary"
                @click="searchFilter"
              >
                <feather-icon icon="FilterIcon" />
                Filtrele
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card-header>
      <template v-if="dataList.length > 0">
        <b-table
          :fields="fields"
          :items="dataList"
          striped
          small
        >
          <template #cell(request_uri)="data">
            <b-link
              v-b-tooltip.hover.top="'Tekrar tetiklemek için tıklayın.'"
              :href="'https://'+data.item.request_uri"
              target="_blank"
              class="font-weight-bold"
            >
              {{ data.item.request_uri }}
            </b-link>
          </template>
          <template #cell(started)="data">
            <div v-if="data.item.started">
              {{ moment(data.item.started).format('DD.MM.YYYY HH:mm:ss') }}
            </div>
          </template>
          <template #cell(finished)="data">
            <div v-if="data.item.finished">
              {{ moment(data.item.finished).format('DD.MM.YYYY HH:mm:ss') }}
            </div>
          </template>
          <template #cell(created)="data">
            <div v-if="data.item.created">
              {{ moment(data.item.created).format('DD.MM.YYYY HH:mm:ss') }}
            </div>
          </template>
          <template #cell(response)="data">
            <div v-if="data.item.response.status">
              <small class="text-success">Başarılı</small>
            </div>
            <div v-else>
              <small class="text-danger">Başarısız</small>
            </div>
            <b-button
              v-b-tooltip.hover.top="'Sonuç cevabını görüntülemek için tıklayın.'"
              variant="flat-primary"
              size="sm"
              @click="setModal(data.item.response)"
            >
              Detay
            </b-button>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </template>
      <pre-loading
        v-else
        class="mb-2"
        message="Kayıtlar Getiriliyor.."
      />
    </b-card>
    <b-modal
      v-model="modal.status"
      title="Detay Görüntüle"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      centered
      size="lg"
    >
      <b-list-group class="font-small-3 h-100">
        <b-list-group-item>
          <div class="text-primary font-weight-bold">
            Durum
          </div>
          <div>
            {{ modal.response.status ? 'Başarılı' : 'Başarısız' }}
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="text-primary font-weight-bold">
            Gelen Yanıt
          </div>
          <div>
            {{ modal.response.detail }}
          </div>
        </b-list-group-item>
      </b-list-group>
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="modal.status = false;modal.response = {}"
        >
          Kapat
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard, BCardFooter, BCardHeader, BCardTitle, BPagination, BTable, BLink, BButton,
  VBTooltip, BListGroupItem, BListGroup, BFormDatepicker, BFormGroup, BInputGroup, BFormTimepicker, BRow, BCol,
} from 'bootstrap-vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'CronjobLogCard',
  components: {
    BFormTimepicker,
    BFormDatepicker,
    BListGroup,
    BListGroupItem,
    PreLoading,
    BCardFooter,
    BPagination,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BLink,
    BButton,
    BFormGroup,
    BInputGroup,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      dataQuery: {
        limit: 25,
        start: 0,
      },
      modal: {
        status: false,
        response: {},
      },
      filter: {
        sdate: this.moment().format('YYYY-MM-DD'),
        stime: '00:00',
        edate: this.moment().format('YYYY-MM-DD'),
        etime: '23:59',
      },
      locale: 'tr',
      locales: [
        { value: 'en-US', text: 'English US (en-US)' },
        { value: 'tr', text: 'Türkçe (tr)' },
      ],
      labels: {
        tr: {
          labelHours: 'Saat',
          labelMinutes: 'Dakika',
          labelSeconds: 'Saniye',
          labelIncrement: 'Arttır',
          labelDecrement: 'Azalt',
          labelSelected: 'Seçildi',
          labelNoTimeSelected: 'Saat Seçiniz',
          labelCloseButton: 'Seç',
        },
      },
      fields: [
        {
          key: 'request_uri',
          label: 'Görev Adresi',
          thClass: 'font-small-2 table-middle text-nowrap',
          tdClass: 'font-small-2 text-nowrap',
        },
        {
          key: 'response',
          label: 'Durum',
          thClass: 'text-center',
          tdClass: 'font-small-2 text-center',
        },
        {
          key: 'request_ip',
          label: 'Tetikleyen IP',
          thClass: 'text-center',
          tdClass: 'font-small-2 text-center',
        },
        {
          key: 'notes',
          label: 'Çalıştırma Notu',
          thClass: 'text-center',
          tdClass: 'font-small-2 text-center',
        },
        {
          key: 'started',
          label: 'BAŞLANGIÇ TARİHİ',
          thClass: 'text-center',
          tdClass: 'font-small-2 text-center',
        },
        {
          key: 'finished',
          label: 'BİTİŞ TARİHİ',
          thClass: 'text-center',
          tdClass: 'font-small-2 text-center',
        },
        {
          key: 'created',
          label: 'İŞLEM KAYIT TARİHİ',
          thClass: 'text-center',
          tdClass: 'font-small-2 text-center',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['cronjobLogs/dataList']
    },
    dataCount() {
      return this.$store.getters['cronjobLogs/dataCounts']
    },
    logLoading() {
      return this.$store.getters['cronjobLogs/dataLoading']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    searchFilter() {
      this.dataQuery = { ...this.dataQuery, ...this.filter }
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('cronjobLogs/getDataList', this.dataQuery)
    },
    setModal(data) {
      this.modal.status = true
      this.modal.response = data
    },
  },
}
</script>
